import React, { useContext, useState } from 'react';
import { AppContext } from '~/providers/AppProvider';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import { Button } from '@boatsetter-backup/js-component-lib';
import SearchGoogleInput from '~/components/homepage/placeSearch';
import PassengersCount from '~/components/homepage/passengersInput';
import DateInput from '~/components/homepage/dateInput';
import { Trans } from '@lingui/react';
import { getCultureToken } from '~/utils/langUtils';
import { redirectBackToBS4 } from '~/components/searchGoogleInput';
import type { BoatRentalTransformed } from '~/utils/rentals';

const Search = ({
  defaultPlace = null,
  tiny = false,
}: {
  defaultPlace?: BoatRentalTransformed | null;
  tiny?: boolean;
}) => {
  const router = useRouter();
  const appCtx = useContext(AppContext);

  const [searchPlace, setSearchPlace] = useState(defaultPlace);
  const [searchError, setSearchError] = useState(false);

  const [passengerCount, setPassengerCount] = useState(null);
  const [searchDate, setSearchDate] = useState(undefined);

  const onSubmit = () => {
    if (searchPlace === null) {
      setSearchError(true);
      return;
    }

    appCtx.actions.setLoading(true);

    setSearchError(false);

    const searchQuery: any = {
      latitudeSw: searchPlace.sw_lat,
      longitudeSw: searchPlace.sw_lng,
      latitudeNe: searchPlace.ne_lat,
      longitudeNe: searchPlace.ne_lng,
      // ...adjustSearchingPerimeter(searchPlace.ne_lat, searchPlace.sw_lat, searchPlace.ne_lng, searchPlace.sw_lng),
      near: searchPlace.near,
      currency: appCtx.state.currency,
    };

    if (appCtx.state.defaultCurrency === appCtx.state.currency) {
      delete searchQuery.currency;
    }

    if (passengerCount !== null) {
      searchQuery.passengerCount = passengerCount;
    }
    if (searchDate !== undefined) {
      searchQuery.availableFrom = searchDate.toISOString();
    }

    if (redirectBackToBS4(searchPlace)) return;

    router.push({
      pathname: `/${getCultureToken(appCtx)}search`,
      query: searchQuery,
    });
  };
  return (
    <SearchWrapper>
      <SearchGoogleInput searchError={searchError} setSearchPlace={setSearchPlace} />
      {!tiny ? (
        <>
          <DateInput setSearchDate={setSearchDate} />
          <PassengersCount setPassengerCount={setPassengerCount} />
        </>
      ) : null}
      <SubmitSearch onClick={onSubmit}>
        <Button small={true} text={<Trans id="rentingIsEasy.point1.heading" />} />
      </SubmitSearch>
    </SearchWrapper>
  );
};

const SearchWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 998;
  background: rgba(255, 255, 255, 0.5);
  padding: 0.5em;
  border-radius: 8px;
`;

const SubmitSearch = styled.div`
  button {
    height: 48px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;

    @media (max-width: 450px) {
      padding-left: 1em !important;
      padding-right: 1em !important;
    }
  }
  button {
    span {
      font-size: 0.8em !important;
      font-weight: 600 !important;
      font-family: AvenirNext, Avenir, Helvetica Neue, Helvetica, Arial, sans-serif;
    }
  }
`;
export default Search;
